import React from 'react';
import { matchDriver, isQualifying } from './Utils';
import { Stack } from '@mui/material';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { useEffect } from 'react';
import { Box } from '@mui/material';

const TimingView = ( {racers, classRacers, race, driver, headerHeight, raceState} ) => {
    const [prevDriver, setPrevDriver] = React.useState(null);
    const [curDriver, setCurDriver] = React.useState(null);
    const [nextDriver, setNextDriver] = React.useState(null);
    const [position, setPosition] = React.useState(null);
    const [classPosition, setClassPosition] = React.useState(null);
    const [fastestRacer, setFastestRacer] = React.useState(null);
    const [fastestClassRacer, setFastestClassRacer] = React.useState(null);

    const getFastestRacer = (racers) => {
        if (!racers || racers.length == 0) {
            return null;
        }

        let fastestRacer = racers[0];

        racers.forEach((racer) => {
            // decode the time to ms from a string like "1:23.456"

            const [min1, sec1, ms1] = fastestRacer.bestLap.split(/[:.]/);
            const [min2, sec2, ms2] = racer.bestLap.split(/[:.]/);

            const time1 = parseInt(min1) * 60000 + parseInt(sec1) * 1000 + parseInt(ms1);
            const time2 = parseInt(min2) * 60000 + parseInt(sec2) * 1000 + parseInt(ms2);

            if (time2 < time1) {
                fastestRacer = racer;
            }
        });

        return fastestRacer;
    }

    useEffect(() => {
        if (!racers || !driver || racers.length == 0 || driver.length == 0) {
            return;
        }

        var myRacerIndex = -1;
        for (var i = 0; i<racers.length; i++) {
            if (matchDriver(driver, racers[i].name)) {
                myRacerIndex = i;
                break;
            }
        }

        if (myRacerIndex == -1) {
            return;
        }

        setCurDriver(racers[myRacerIndex]);
        setPosition(myRacerIndex + 1);

        var myRacerClassIndex = -1;
        for (var j = 0; j<classRacers[racers[myRacerIndex].class].racers.length; j++) {
            if (matchDriver(driver, classRacers[racers[myRacerIndex].class].racers[j].name)) {
                myRacerClassIndex = j;
                break;
            }
        }

        if (myRacerClassIndex == -1) {
            console.log("TimingView: class racer not found");
            return;
        }        

        setClassPosition(myRacerClassIndex + 1);
        setFastestRacer(getFastestRacer(racers));
        setFastestClassRacer(getFastestRacer(classRacers[racers[myRacerIndex].class].racers));

        if (myRacerIndex > 0) {
            setPrevDriver(racers[myRacerIndex - 1]);
        }

        if (myRacerIndex < racers.length - 1) {
            setNextDriver(racers[myRacerIndex + 1]);
        }
    }, [racers, driver, classRacers]);


    return (
        <Stack spacing={1} 
            direction="column" 
            justifyContent="space-between" 
            alignItems="stretch" 
            divider={<Divider orientation="horizontal" flexItem />}
            sx={{ height: `calc(100% - ${headerHeight}px)`, paddingTop: 2 }}
            >
            <Box>
                <Stack direction="row" justifyContent="space-between" alignItems="stretch" spacing={2} sx={{ flexGrow: 1 }}>
                    <Box>
                        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1} sx={{ flexGrow: 1 }}>
                            <Typography variant="h5" component="div">
                                Last lap
                            </Typography>
                            <Typography variant="h1" component="div">
                                { curDriver ? curDriver.lastLap : ""}
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1} sx={{ flexGrow: 1 }}>
                            <Typography variant="h5" component="div">
                                Best lap
                            </Typography>
                            <Typography variant="h1" component="div">
                                { curDriver ? curDriver.bestLap : "Pick a driver"}
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1} sx={{ flexGrow: 1 }}>
                            <Typography variant="h5" component="div" align='right'>
                                Laps
                            </Typography>
                            <Typography variant="h1" component="div" align='right'>
                                { curDriver ? curDriver.laps : ""}
                            </Typography>
                            <Typography variant="h4" component="div" align='right'>
                                { raceState && raceState.time ? raceState.time : ""}
                            </Typography>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
            <Box>
                <Stack direction="row" justifyContent="space-between" alignItems="stretch" spacing={2} sx={{ flexGrow: 1 }}>
                    <Box>
                        <Stack direction="column" justifyContent="space-between" alignItems="stretch" spacing={1} sx={{ flexGrow: 1 }}>
                            <Typography variant="h5" component="div">
                                Class Position
                            </Typography>
                            <Typography variant="h2" component="div">
                                { curDriver ? classPosition : "" }
                            </Typography>
                            <Typography variant="standingsClass" component="span" align="center" noWrap sx={{ backgroundColor: curDriver && classRacers && classRacers[curDriver.class] ? classRacers[curDriver.class].color : "inherit" }} >
                                { curDriver ? curDriver.class : "" }
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <Stack direction="column" justifyContent="space-between" alignItems="stretch" spacing={1} sx={{ flexGrow: 1 }}>
                            <Typography variant="h5" component="div">
                                Class Best
                            </Typography>
                            <Typography variant="h2" component="div">
                                { fastestClassRacer ? fastestClassRacer.bestLap : "" }
                            </Typography>
                            <Typography variant="h5" component="div">
                                { fastestClassRacer ? fastestClassRacer.name.toUpperCase() : "" }
                            </Typography>
                        </Stack>
                    </Box>
                    <Divider orientation="vertical" flexItem /> {/* Add vertical divider here */}
                    <Box>
                        <Stack direction="column" justifyContent="space-between" alignItems="stretch" spacing={1} sx={{ flexGrow: 1 }}>
                            <Typography variant="h5" component="div">
                                Overall Position
                            </Typography>
                            <Typography variant="h2" component="div">
                                { curDriver ? position : "" }
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <Stack direction="column" justifyContent="space-between" alignItems="stretch" spacing={1} sx={{ flexGrow: 1 }}>
                            <Typography variant="h5" component="div">
                                Overall Best
                            </Typography>
                            <Typography variant="h2" component="div">
                                { fastestRacer ? fastestRacer.bestLap : "" }
                            </Typography>
                            <Typography variant="h5" component="div">
                                { fastestRacer ? fastestRacer.name.toUpperCase() : "" }
                            </Typography>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
            <Stack spacing={2} direction="column" justifyContent="flex-start" alignItems="stretch">
                <Stack direction="row" 
                    spacing={2} 
                    divider={<Divider orientation="vertical" flexItem />}
                    sx={{ flexGrow: 1 }}>
                    <Typography noWrap variant="h6" sx={{ width: 1/10 }}>                        
                    </Typography>
                    <Typography variant="h6" component="span" align="center" sx={{ width: 1/15 }} >
                        Pos
                    </Typography>
                    <Typography variant="h6" component="span" align="center" noWrap sx={{ width: 1/12 }} >
                        Class
                    </Typography>
                    <Typography noWrap variant="h6" sx={{ flexGrow: 1 }}>
                        Name
                    </Typography>
                    <Typography variant="h6" sx={{ minWidth: 1/10 }} align="right">
                        Interval
                    </Typography>
                    <Typography variant="h6" sx={{ minWidth: 1/10 }} align="right">
                        { isQualifying(raceState) ? "Best" : "Last" }
                    </Typography>
                </Stack>

                { prevDriver && 
                (<Stack direction="row" 
                    spacing={2} 
                    divider={<Divider orientation="vertical" flexItem />}
                    sx={{ flexGrow: 1 }}>
                    <Typography noWrap variant="standingsName" sx={{ width: 1/10 }}>
                        Ahead:
                    </Typography>
                    <Typography variant="standingsPosition" component="span" align="center" sx={{ width: 1/15, backgroundColor: 'secondary.dark' }} >
                        {prevDriver ? position - 1 : ""}
                    </Typography>
                    <Typography variant="standingsClass" component="span" align="center" noWrap sx={{ width: 1/12, backgroundColor: prevDriver && classRacers && classRacers[prevDriver.class] ? classRacers[prevDriver.class].color : "inherit" }} >
                        {prevDriver ? prevDriver.class : "" }
                    </Typography>
                    <Typography noWrap variant="standingsName" sx={{ flexGrow: 1 }}>
                        {prevDriver ? prevDriver.name.toUpperCase() : ""}
                    </Typography>
                    <Typography variant="standingsTime" sx={{ minWidth: 1/10 }} align="right">
                        {prevDriver && curDriver ? "-" + curDriver.gap : ""}
                    </Typography>
                    <Typography variant="standingsTime" sx={{ minWidth: 1/10 }} align="right">
                        {prevDriver ? (isQualifying(raceState) ? prevDriver.bestLap : prevDriver.lastLap) : ""}
                    </Typography>
                </Stack>)}
                { nextDriver &&
                (<Stack direction="row" 
                    spacing={2} 
                    divider={<Divider orientation="vertical" flexItem />}
                    sx={{ flexGrow: 1 }}>
                    <Typography noWrap variant="standingsName" sx={{ width: 1/10 }}>
                        Behind:
                    </Typography>
                    <Typography variant="standingsPosition" component="span" align="center" sx={{ width: 1/15, backgroundColor: 'secondary.dark' }} >
                        {nextDriver ? position + 1 : ""}
                    </Typography>
                    <Typography variant="standingsClass" component="span" align="center" noWrap sx={{ width: 1/12, backgroundColor: nextDriver && classRacers && classRacers[nextDriver.class] ? classRacers[nextDriver.class].color : "inherit" }} >
                        {nextDriver ? nextDriver.class : "" }
                    </Typography>
                    <Typography noWrap variant="standingsName" sx={{ flexGrow: 1 }}>
                        {nextDriver ? nextDriver.name.toUpperCase() : ""}
                    </Typography>
                    <Typography variant="standingsTime" sx={{ minWidth: 1/10 }} align="right">
                        {nextDriver ? "+" + nextDriver.gap : ""}
                    </Typography>
                    <Typography variant="standingsTime" sx={{ minWidth: 1/10 }} align="right">
                        {nextDriver ? (isQualifying(raceState) ? nextDriver.bestLap : nextDriver.lastLap) : ""}
                    </Typography>
                </Stack>)}
            </Stack>
        </Stack>
    );
};

export default TimingView;
