import React, { useState } from 'react';
import { Paper, Stack, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { matchDriver } from './Utils';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import FirstTimeUserExperience from './FirstTimeUserExperience';

const Standings = ( {racers, classRacers, race, driver, headerHeight, changeSelectedDriverHandler} ) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm')); // < 600px
    const isMedium = useMediaQuery(theme.breakpoints.down('md')); // < 1000px
    const isLarge = useMediaQuery(theme.breakpoints.up('sm')); // >= 600px
    const [ stackItems, setStackItems ] = useState(null);
    
    useEffect(() => {
        var myStackItems = null;
        if (racers && racers.length > 0) {
            myStackItems = (
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center' }} >
                        <Stack direction="row" justifyContent="space-between" alignItems="stretch" spacing={isMedium ? 1 : 2} sx={{ flexGrow: 1 }}>
                            <Typography variant="h6" component="span" align="center" noWrap sx={{ width: '5%', ...(isMedium ? { fontSize: '0.7rem' } : {})}} >
                                Pos
                            </Typography>
                            <Typography variant="h6" component="span" align="center" noWrap sx={{ width: '8%', ...(isMedium ? { fontSize: '0.7rem' } : {}) }} >
                                Class
                            </Typography>
                            <Typography variant="h6" component="span" noWrap sx={{ width: '30%', ...(isMedium ? { fontSize: '0.7rem' } : {}) }} >
                                Name
                            </Typography>
                            { isLarge && (<>
                                <Typography variant="h6" component="span" align="center" noWrap sx={{ width: '8%', ...(isMedium ? { fontSize: '0.7rem' } : {}) }} >
                                    Num
                                </Typography>
                            </>)}
                            <Typography variant="h6" component="span" noWrap sx={{ minWidth: '12%', ...(isMedium ? { fontSize: '0.7rem' } : {}) }} align="right">
                                Interval
                            </Typography>
                            <Typography variant="h6" component="span" noWrap sx={{ minWidth: '12%', ...(isMedium ? { fontSize: '0.7rem' } : {}) }} align="right">
                                Last
                            </Typography>
                            { isLarge && (<>
                                <Typography variant="h6" component="span" noWrap sx={{ minWidth: '12%', ...(isMedium ? { fontSize: '0.7rem' } : {}) }} align="right">
                                    Best
                                </Typography>
                            </>)}
                        </Stack>
                    </Box>
                    <Stack spacing={isMedium ? 1 : 2} direction="column" justifyContent="flex-start" alignItems="stretch">
                        { racers.map((racer, index) => (
                            <Box sx={{ 
                                // height: '7vh', 
                                height: isMedium ? '2rem' : '3.3rem', 
                                display: 'flex', 
                                alignItems: 'center', 
                                border: 1,
                                backgroundColor: matchDriver(driver, racer.name) ? 'primary.dark' : 'inherit',
                                color: matchDriver(driver, racer.name) ? 'white' : 'inherit' }} key={racer.name + racer.number}
                                onClick={() => changeSelectedDriverHandler(racer.name)}>
                                <Stack direction="row" justifyContent="space-between" alignItems="stretch" spacing={isMedium ? 1 : 2} sx={{ flexGrow: 1 }}>
                                    <Typography variant="standingsPosition" component="span" noWrap align="center" sx={{ width: '5%', backgroundColor: 'secondary.dark', ...(isMedium ? { fontSize: '0.95rem' } : {}) }} >
                                        {index + 1}
                                    </Typography>
                                    <Typography variant="standingsClass" component="span" align="center" noWrap sx={{ width: '8%', backgroundColor: classRacers[racer.class].color, ...(isMedium ? { fontSize: '0.95rem' } : {}) }} >
                                        {racer.class}
                                    </Typography>
                                    <Typography variant="standingsName" component="span" noWrap sx={{ width: '30%', ...(isMedium ? { fontSize: '0.95rem' } : {}) }} >
                                        {racer.name.toUpperCase()}
                                    </Typography>
                                    { isLarge && (<>
                                        <Typography variant="standingsNumber" component="span" noWrap align="center" sx={{ width: '8%', ...(isMedium ? { fontSize: '0.95rem' } : {}) }} >
                                            {racer.number}
                                        </Typography>
                                    </>)}
                                    <Typography variant="standingsTime" component="span" noWrap sx={{ width: '12%', ...(isMedium ? { fontSize: '0.95rem' } : {}) }} align="right">
                                        {racer.gap}
                                    </Typography>
                                    <Typography variant="standingsTime" component="span" noWrap sx={{ width: '12%', ...(isMedium ? { fontSize: '0.95rem' } : {}) }} align="right">
                                        {racer.lastLap}
                                    </Typography>
                                    { isLarge && (<>
                                        <Typography variant="standingsTime" component="span" noWrap sx={{ width: '12%', ...(isMedium ? { fontSize: '0.95rem' } : {}) }} align="right">
                                            {racer.bestLap}
                                        </Typography>
                                    </>)}
                                </Stack>
                            </Box>
                        )) }
                    </Stack>
                </>
            );
        }
        else {
            var pendingMessage = `Waiting for data for race`
            if (race) {
                if (race.name) {
                    pendingMessage += ` "${race.name}"`
                }
                if (race.islive != "yes") {
                    pendingMessage += `, race is not live yet`
                }    
            }
            myStackItems = (
                <FirstTimeUserExperience message={pendingMessage}/>
            );
        };

        setStackItems(myStackItems);
    }, [racers]);

    return (
        <Box sx={{ height: `calc(100% - ${headerHeight}px)`, overflow: "auto" }}>
            {stackItems}
        </Box>
    );
};

export default Standings;
