import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getRelDiff, isQualifying } from './Utils';

function BestVsLeaderBest( {curDriver, leaderRacer, raceState} ) {
    if (!curDriver || !leaderRacer || !raceState) { 
        return <Box></Box>;
    }

    if (isQualifying(raceState) && (!curDriver.bestLap || !leaderRacer.bestLap)) {
        return <Box></Box>;
    }

    if (!isQualifying(raceState) && (!curDriver.lastLap || !leaderRacer.lastLap)) {
        return <Box></Box>;
    }

    let bestLast = isQualifying(raceState) ? "Best" : "Last";
    let relDelta = isQualifying(raceState) ? getRelDiff(curDriver.bestLap, leaderRacer.bestLap) : getRelDiff(curDriver.lastLap, leaderRacer.lastLap);
    let colorString = (relDelta && relDelta[0] == "+") ? "red" : "green";
    
    return (
        <Box>
            <Stack direction="column" justifyContent="space-between" alignItems="stretch" spacing={1} sx={{ flexGrow: 1 }}>
                <Typography variant="h6" component="div">
                    {bestLast} vs Leader {bestLast}
                </Typography>
                <Typography variant="h2" component="div" sx={{ color: colorString }}>
                    {relDelta}
                </Typography>
            </Stack>
        </Box>
    );
}

export default BestVsLeaderBest;
