import React from 'react';
import { Stack, Typography, Divider } from '@mui/material';
import { isQualifying } from './Utils';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

function ClassViewTableHeader( { raceState }) {
    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.down('md')); // < 1000px

    return (
        <Stack direction="row" 
        spacing={2} 
        divider={<Divider orientation="vertical" flexItem />}
        sx={{ flexGrow: 1 }}>
            <Typography variant="h6" component="span" align="center" sx={{ width: '5%', ...(isMedium ? { fontSize: '0.7rem' } :{}) }} >
                Pos
            </Typography>
            <Typography variant="h6" component="span" align="center" noWrap sx={{ width: '8%', ...(isMedium ? { fontSize: '0.7rem' } :{}) }} >
                Class
            </Typography>
            <Typography noWrap variant="h6" sx={{ width: '40%', ...(isMedium ? { fontSize: '0.7rem' } :{}) }}>
                Name
            </Typography>
            <Typography variant="h6" component="span" align="center" sx={{ width: '8%', ...(isMedium ? { fontSize: '0.7rem' } :{}) }} >
                No
            </Typography>
            <Typography variant="h6" sx={{ width: '13%', ...(isMedium ? { fontSize: '0.7rem' } :{}) }} align="right">
                Interval
            </Typography>
            <Typography variant="h6" sx={{ width: '12%', ...(isMedium ? { fontSize: '0.7rem' } :{}) }} align="right">
                { isQualifying(raceState) ? "Best" : "Vs Last" }
            </Typography>
        </Stack>
    );
}

export default ClassViewTableHeader;
