import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';

const LastBestLap = ( { curDriver } ) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm')); // < 600px
    const isMoreThanSmall = useMediaQuery(theme.breakpoints.up('sm')); // >= 600px
    const isTall = useMediaQuery('(min-height:500px)'); // >= 500px height

    return (
        <>
            <Box>
                <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1} sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="div">
                        Last lap
                    </Typography>
                    <Typography variant={isSmall || (isMoreThanSmall && !isTall) ? "h3" : "h2"} component="div">
                        { curDriver ? curDriver.lastLap : ""}
                    </Typography>
                </Stack>
            </Box>
            <Box>
                <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1} sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="div">
                        Best lap
                    </Typography>
                    <Typography variant={isSmall || (isMoreThanSmall && !isTall) ? "h3" : "h2"} component="div">
                        { curDriver ? curDriver.bestLap : "Pick a driver"}
                    </Typography>
                </Stack>
            </Box>
        </>
    );
};

export default LastBestLap;
